<template>
  <div id="static-map">
    <Loader v-if="loading" :full="false" />
  </div>
</template>

<script>
import Loader from '@/components/views/main/loader/Loader';
import './map.scss';

export default {
  name: 'SearchStaticMap',
  components: {
    Loader
  },
  props: {
    address: {
      type: String,
      default: '',
      require: true
    },
    defaultZoom: {
      type: Number,
      default: 16
    }
  },
  data() {
    return {
      loading: true,
      map: null,
      zoom: 16,
      behaviorsOptions: [
        'drag',
        'rightMouseButtonMagnifier',
        'dblClickZoom',
        'multiTouch',
        'scrollZoom'
      ]
    };
  },
  watch: {
    address() {
      this.getCoordinatesByAddress();
    },
    defaultZoom(zoom) {
      this.zoom = zoom;
    }
  },
  created() {
    if (document.getElementById('script-yandex-maps') === null) {
      // Установить скрипты для использования карты
      const scriptMap = document.createElement('script');
      scriptMap.id = 'script-yandex-maps';
      scriptMap.setAttribute(
        'src',
        'https://api-maps.yandex.ru/2.1/?apikey=' +
          process.env.VUE_APP_YANDEX_MAPS_API_KEY +
          '&lang=ru_RU'
      );
      document.head.appendChild(scriptMap);

      // Инициализировать карту
      scriptMap.addEventListener('load', this.initializeYandexMap);
    } else {
      this.initializeYandexMap();
    }
  },
  beforeDestroy() {
    if (
      this.map !== null &&
      this.map !== undefined &&
      Object.hasOwnProperty.call(this.map, 'destroy')
    ) {
      this.map.destroy();
    }
  },
  methods: {
    setMapOptions(coordinates) {
      if (this.map !== null) {
        this.map.destroy();
      }

      /* global ymaps */
      this.map = new ymaps.Map(
        'static-map',
        {
          center: coordinates,
          zoom: this.zoom,
          controls: ['zoomControl', 'fullscreenControl', 'typeSelector']
        },
        {
          suppressMapOpenBlock: true
        }
      );

      this.map.behaviors.disable(this.behaviorsOptions);

      this.map.container.events.add('fullscreenenter', () => {
        this.map.behaviors.enable(this.behaviorsOptions);
      });

      this.map.container.events.add('fullscreenexit', () => {
        this.map.behaviors.disable(this.behaviorsOptions);
        this.map.setCenter(coordinates, this.zoom);
      });

      this.setMarkers(coordinates);
    },
    initializeYandexMap() {
      ymaps.ready(() => {
        this.getCoordinatesByAddress();
      });
    },
    setMarkers(coordinates) {
      let placemark = new ymaps.Placemark(coordinates);
      this.map.geoObjects.add(placemark);

      this.loading = false;
    },
    getCoordinatesByAddress() {
      if (!this.loading) {
        this.loading = true;
      }

      const myGeocoder = ymaps.geocode(this.address); // пытаюсь передать переменную
      myGeocoder.then(
        response => {

          const coordinates = response.geoObjects
            .get(0)
            .geometry.getCoordinates(); // записываю координаты в переменную
          this.setMapOptions(coordinates);
        },
        error => {
          // обработка ошибки
          console.log(error);
        }
      );
    }
  }
};
</script>
